var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "transport-modal",
        size: "lg",
        title: _vm.FormMSG(25, "Transport"),
        "ok-title": _vm.createByOtherUser
          ? _vm.FormMSG(48, "Close")
          : _vm.FormMSG(26, "Save"),
        "cancel-title": _vm.FormMSG(27, "Cancel"),
        "cancel-variant": "outline-primary",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-only": _vm.createByOtherUser,
        "ok-variant": _vm.createByOtherUser ? "outline-primary" : "primary",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
        cancel: _vm.handleCancel,
        hidden: _vm.handleClickHidden,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        [
          _c("form-transport", {
            attrs: {
              "edit-data": _vm.transport,
              "submit-form": _vm.submitFormTransport,
              "reset-form": _vm.resetFormTransport,
              "is-my-transport": _vm.isMyTransport,
            },
            on: {
              "form-transport:submit-success":
                _vm.handleFormTransportSubmitSuccess,
              "form-transport:submit-failed":
                _vm.handleFormTransportSubmitFailed,
              "form-transport:reset-data-success":
                _vm.handleFormTransportResetDataSuccess,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }