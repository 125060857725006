<template>
	<b-modal
		id="transport-modal"
		v-model="isOpen"
		size="lg"
		:title="FormMSG(25, 'Transport')"
		:ok-title="createByOtherUser ? FormMSG(48, 'Close') : FormMSG(26, 'Save')"
		:cancel-title="FormMSG(27, 'Cancel')"
		cancel-variant="outline-primary"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		:ok-only="createByOtherUser"
		:ok-variant="createByOtherUser ? 'outline-primary' : 'primary'"
		@ok.prevent="handleOk"
		@cancel="handleCancel"
		@hidden="handleClickHidden"
		modal-class="mui-animation"
		:fade="false"
	>
		<div>
			<form-transport
				:edit-data="transport"
				:submit-form="submitFormTransport"
				:reset-form="resetFormTransport"
				:is-my-transport="isMyTransport"
				@form-transport:submit-success="handleFormTransportSubmitSuccess"
				@form-transport:submit-failed="handleFormTransportSubmitFailed"
				@form-transport:reset-data-success="handleFormTransportResetDataSuccess"
			/>
		</div>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

import { getTransport } from '@/cruds/transports.crud';

import FormTransport from './FormTransport';

export default {
	name: 'TransportModal',

	mixins: [languageMessages, GlobalMixin],

	components: {
		FormTransport
	},

	props: {
		isMyTransport: { type: Boolean, default: false },
		open: { type: Boolean, default: false },
		editData: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			transport: null,
			submitFormTransport: false,
			createByOtherUser: false,
			resetFormTransport: false
		};
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	watch: {
		editData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}') {
					this.transport = newVal;
					if (this.isMyTransport) {
						if (parseInt(newVal.userId) === parseInt(newVal.createdBy)) {
							this.createByOtherUser = false;
						} else {
							this.createByOtherUser = true;
						}
					}
				}
			},
			immediate: true
		}
	},

	methods: {
		handleFormTransportSubmitFailed() {
			this.submitFormTransport = false;
		},
		handleFormTransportResetDataSuccess() {
			this.resetFormTransport = false;
			this.emitEventClose();
		},
		handleFormTransportSubmitSuccess() {
			this.$emit('transport-modal:submit-success');
			this.submitFormTransport = false;
			this.emitEventClose();
		},
		handleOk() {
			if (!this.createByOtherUser) {
				this.submitFormTransport = true;
			} else {
				this.handleCancel();
			}
		},
		emitEventClose() {
			this.$emit('transport-modal:close');
		},
		handleCancel() {
			this.resetFormTransport = true;
		},
		handleClickHidden() {
			this.emitEventClose();
		}
	}
};
</script>
